import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';
import parse from 'html-react-parser';

import BannersStore, { BannerComponent } from '../../../stores/Banners';
import Image from '../../Image';
import { Sizes } from '../../../constants/Image';
import LocalizedLink from '../../LocalizedLink';

import './styles/index.scss';

interface Props {
  banners?: BannersStore;
}

@inject('banners')
@observer
export default class AboutUs extends React.Component<Props> {
  @observable banners = [];

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  public componentDidMount() {
    this.loadBanners();
  }

  async loadBanners() {
    const { banners } = this.props;
    const bannerImages = await banners.loadByComponent(BannerComponent.ABOUT);

    this.setBanners(bannerImages);
  }

  @action setBanners(banners) {
    this.banners = banners;
  }

  render() {
    if (!this.banners.length) {
      return null;
    }

    const offerItem = this.banners.map((banner) => {
      const className = `work-item ${banner.collection}`;
      let content;
      let button;
      let topImageContent;
      let image;

      if (banner.images) {
        image = banner.images[0];
      }

      if (banner.button) {
        button = (
          <div className="button">
            <LocalizedLink to={banner.url}>{banner.button}</LocalizedLink>
          </div>
        );
      }

      if (banner.content) {
        content = <div className="work-content">{parse(banner.content)}</div>;
      }

      if (banner.collection === 'top') {
        topImageContent = (
          <div className="top-title">{parse(banner.content)}</div>
        );
      }

      return (
        <div className={className} key={banner.id}>
          <div className="info">
            {content}
            {button}
          </div>
          <div className="picture">
            <Image
              className={'picture-item'}
              image={image}
              defaultSize={Sizes.W800}
              allSizes={[Sizes.W800]}
            />
            {topImageContent}
          </div>
        </div>
      );
    });

    return (
      <div className="component-about-us">
        <div className="work">{offerItem}</div>
      </div>
    );
  }
}
